import { createApp } from 'vue'
import App from './AppHome.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const mainHome = createApp(App)
mainHome.use(VueAxios, axios)

// now you can see `vueApp` within the browser console
window.vueAppBulk = mainHome

mainHome.mount('#app_home')
