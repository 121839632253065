<template>
  <div class="search">
    <div class="container">
      <form id="frmFindDomain" action="/index/check-domains" method="POST">
        <div class="txt">
          DOMAIN NAME REGISTRATION SERVICES
          <br />
          IN OVER
          <span class="mob-nowrap mob-blue">{{ extensionsCount }} EXTENSIONS</span>
        </div>
        <!-- end .txt-->
        <div class="input">
          <input
            class="btn full"
            value="Find your domain"
            type="button"
            onclick="checkDomainName();"
          />
          <div class="nofloat">
            <input
              id="domainName"
              class="placeholder"
              placeholder="Enter domain name or keyword"
              value=""
              type="text"
              name="strDomainName"
            />
          </div>
        </div>
        <!-- end .input-->
        <div class="all2">
          <a href="/index/pricing">Show all</a>
          <!-- Need base url -->
        </div>
        <ul class="checks">
          <li v-for="(item, index) in tldCheckBoxes" :key="index">
            <label>
              <input type="checkbox" name="tlds[]" :value="index.toUpperCase()" />
              <span></span>
            </label>
            <a :href="`/tld/get-info?tld=${index}`" class="uppercase">.{{ index }}</a>
            ${{ getTldPrice(index) }}
          </li>
        </ul>
        <!-- end .checks-->
      </form>
    </div>
    <!-- end .container-->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'HomePage',
  setup() {
    const tldCheckBoxes = ref(Object)
    const extensionsCount = ref('')
    const getTldPrice = function (index) {
      const currentTld = tldCheckBoxes.value[index]
      return currentTld.isPromo ? currentTld.promoPrice : currentTld.commonPrice
    }

    onMounted(() => (tldCheckBoxes.value = window.homeTldChecks))
    onMounted(() => (extensionsCount.value = window.extensionsCount))

    return {
      tldCheckBoxes,
      extensionsCount,
      getTldPrice,
    }
  },
}
</script>
